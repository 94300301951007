main {
  width: 70%;
  min-height: 480px;
  background: whitesmoke;
  border-radius: 12px;
  margin: 1rem auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 30px;
}

h1 {
  margin-top: 0;
}

.search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-search {
  width: 60%;
}

.user-search input {
  width: 100%;
}

.clear-users button,
.user-search input {
  padding: 0.6rem;
  outline: none;
  border-radius: 6px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.clear-users button {
  background-color: dodgerblue;
  color: white;
}

.clear-users button:hover {
  background-color: rgba(30, 143, 255, 0.776);
  cursor: pointer;
}

/* users */

.users-list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.user {
  margin-bottom: 12px;
  width: 260px;
  height: 120px;
  padding: 18px 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
}

.user img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.user-img {
  display: flex;
  align-items: center;
  width: 50%;
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.user-img h3 {
  font-size: 14px;
}

.user-skills {
  font-size: 10px;
}

ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 2px;
}

ul li {
  border: 1px solid dodgerblue;
  color: dodgerblue;
  padding: 6px;
  margin-top: 2px;
  border-radius: 16px;
}
